import * as React from 'react'

const LogoGlyph = props => (
  <svg
    width="31"
    height="39"
    viewBox="0 0 31 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#20DE86"
      d="M1.11832 18.8161C2.02559 18.3824 3.43691 17.9487 4.31058 17.7819C6.46116 17.3482 9.88864 17.3482 12.7785 17.3482C12.7785 18.6826 12.8457 20.3841 12.7785 21.7186C8.98137 21.7186 6.46116 22.0855 3.77293 23.8537C0.580671 26.0556 -0.0913845 29.4585 0.00942383 32.2275C0.244643 36.1309 2.89926 39 7.06601 39C13.6858 39 18.1549 35.1634 18.1549 26.9897C21.5824 26.9897 24.6067 26.8563 26.5556 25.8221C28.7062 24.6544 29.8823 22.0522 29.0086 19.8503C28.1013 20.3841 26.9925 20.8845 25.0771 21.3182C23.8338 21.6185 21.5152 21.9187 18.2221 21.9187C18.2221 21.2515 18.1549 18.0821 18.2221 17.4149C24.2034 17.4149 27.5637 15.3464 29.143 12.9778C30.3191 11.2763 31.092 9.27459 30.9912 6.2053C30.756 3.16938 28.4038 0 23.8338 0C16.878 0 12.8457 4.6373 12.8457 12.3105C9.14938 12.3105 5.55388 12.1437 3.47051 13.278C1.38714 14.4457 0.580671 16.9145 1.11832 18.8161ZM18.1885 12.3105C18.1885 6.70573 19.8351 2.00171 24.0018 2.00171C26.8244 2.00171 28.6054 3.63644 28.6054 6.13858C28.6054 10.7092 23.8338 12.3105 18.1885 12.3105ZM5.25146 27.8905C7.33483 26.7896 9.72063 26.8563 12.8121 26.8563C12.7785 32.0274 11.6024 37.0317 6.9652 37.0317C4.37779 37.0317 2.22721 35.4636 2.22721 32.4611C2.29441 30.3259 3.57132 28.6912 5.25146 27.8905Z"
    />
  </svg>
)

export default LogoGlyph
