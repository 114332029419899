import * as React from 'react'
import styled from 'styled-components'
import { NewsletterInput } from '.'

interface Props {
  /** Field `<label /> value` */
  label: string | React.ReactNode
  /** Form element id (a11y) */
  inputId: string
  /** Text `<input />`'s `placeholder` attribute */
  placeholder: string
}

type State = {}

export const StyledAside = styled.aside`
  background-color: var(--color-mint);

  .container {
    padding-top: 50px;
    padding-bottom: 29px;
    align-items: center;
  }

  label {
    text-align: center;

    & > p {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (min-width: 768px) {
    .container {
      padding: 60px 0;
    }

    label {
      text-align: left;

      & > p {
        max-width: 100%;
      }
    }
  }
`

export default class NewsletterHuge extends React.Component<Props, State> {
  public render() {
    const { label, inputId, placeholder } = this.props
    return (
      <StyledAside>
        <div className="container container--grid">
          <div className="container__row">
            <NewsletterInput
              appearance="huge"
              label={label}
              inputId={inputId}
              placeholder={placeholder}
            />
          </div>
        </div>
      </StyledAside>
    )
  }
}
