import anime from 'animejs'

export default function useAnimatedScrollTo(id: string) {
  return () => {
    const container =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement
    const el = document.querySelector(`#${id}`)
    if (history && history.pushState) history.pushState(null, null, '#' + id)
    if (el && container) {
      anime({
        duration: 800,
        easing: 'easeOutSine',
        scrollTop: el.offsetTop - 24,
        targets: container,
      })
    }
  }
}
