import * as React from 'react'

const Medium = props => (
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.71396 2.68297C1.72856 2.53564 1.67354 2.39002 1.56581 2.29086L0.468456 0.940911V0.739258H3.87575L6.5094 6.6376L8.82483 0.739258H12.073V0.940911L11.1348 1.85955C11.0539 1.92251 11.0138 2.026 11.0305 2.12842V8.87819C11.0138 8.98061 11.0539 9.0841 11.1348 9.14706L12.0511 10.0657V10.2674H7.44216V10.0657L8.39137 9.12466C8.48464 9.02943 8.48464 9.00142 8.48464 8.85578V3.39995L5.8455 10.2449H5.48886L2.41626 3.39995V7.98756C2.39065 8.18043 2.45337 8.37462 2.58635 8.51409L3.82088 10.0433V10.2449H0.320312V10.0433L1.55484 8.51409C1.68685 8.37438 1.74592 8.17891 1.71396 7.98756V2.68297Z"
      fill="currentColor"
    />
  </svg>
)

export default Medium
