import * as React from 'react'

const Arrow = props => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5332 0.676758L15.6667 6.73209L10.5201 12.3382L9.78347 11.662L13.8623 7.21893L0 7.21893L0 6.21893L13.9206 6.21893L9.77041 1.32341L10.5332 0.676758Z"
      fill="currentColor"
    />
  </svg>
)

export default Arrow
