import * as React from 'react'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

import '../styles/main.scss'

import { transform as toGlobals } from '../data/globals'

import { ContactProvider, ContactConsumer } from '../context/contact'
import { MenuProvider, MenuConsumer } from '../context/menu'

import { SEO } from '../components/seo'
import { Marquee } from '../components/marquee'
import { NewsletterCTA } from '../components/newsletter'
import { Footer } from '../components/footer'
import { Navigation } from '../components/navigation'
import { ContactModal } from '../components/navigation/contact'
import { Menu } from '../components/navigation'

import imageSocial from '../images/social.png'

const MENU_ID = 'navigation-menu'
const NEWSLETTER_ID = 'footer-newsletter-cta'
const THIS_YEAR = new Date().getFullYear()
// const MAILCHIMP_DFBI_GENERAL_INTEREST = '3738e548b8'
const MAILCHIMP_DFBI_CASE_STUDY_INTEREST = 'fe3396b777'
const DFBI_NAV = [
  {
    id: 'case-studies',
    text: 'Case Studies',
    location: '/business-impact#case-studies',
  },
  {
    id: 'events',
    text: 'Events',
    location: '/business-impact#events',
  },
]

interface Props {
  children: React.ReactNode
  pageContext: { layout: string }
}

const LAYOUT_QUERY = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        organization
        contactIframeSrc
      }
    }
    globals: allContentfulSettingsDesignerFund(limit: 1) {
      edges {
        node {
          ...GlobalsFields
        }
      }
    }
  }
`

export default function LayoutData(props: Props) {
  return (
    <StaticQuery
      query={LAYOUT_QUERY}
      render={data => {
        const { layout } = props.pageContext
        const [globals] = data.globals.edges.map(({ node }) => toGlobals(node))
        const { meta } = data.site
        const isDFBIPage = layout === 'business-impact'
        const isMarketingPage = layout === 'marketing-page'
        return (
          <LayoutComponents
            {...props}
            marquee={
              globals.marqueeEnabled && globals.marquee
                ? globals.marquee
                : undefined
            }
            primaryNavigation={isDFBIPage ? DFBI_NAV : globals.navigation}
            showContactInNavigation={false}
            contactIframeSrc={meta.contactIframeSrc}
            newsletterTitle={
              isDFBIPage
                ? 'Stay up to date on new case studies, events, and workshops'
                : globals.newsletterCta.title
            }
            showNewsletterCTA={!isMarketingPage}
            newsletterAppearance={isDFBIPage ? layout : undefined}
            newsletterInterests={
              isDFBIPage ? [MAILCHIMP_DFBI_CASE_STUDY_INTEREST] : undefined
            }
            copyrightOwner={meta.organization}
            copyrightYear={THIS_YEAR}
            footerHeading={globals.footer.title}
            footerLinkGroups={globals.footer.links}
            footerAppearance={isDFBIPage ? layout : undefined}
            privacyPolicyLink={{
              id: 'privacy-policy',
              text: 'Privacy Policy',
              location: '/privacy-policy',
            }}
            termsOfUseLink={{
              id: 'terms-of-use',
              text: 'Terms of Use',
              location: '/terms-of-use',
            }}
          />
        )
      }}
    />
  )
}

function LayoutComponents({
  primaryNavigation,
  showContactInNavigation,
  showNewsletterCTA,
  newsletterTitle,
  newsletterAppearance,
  newsletterInterests,
  marquee,
  contactIframeSrc,
  copyrightOwner,
  copyrightYear,
  footerHeading,
  footerLinkGroups,
  footerAppearance,
  privacyPolicyLink,
  termsOfUseLink,
  children,
}) {
  return (
    <LayoutStructure
      children={children}
      seo={() => <SEO image={imageSocial} />}
      navigation={() => (
        <Navigation
          primary={primaryNavigation}
          contact={showContactInNavigation}
        />
      )}
      menu={({ visible, setVisible }) => (
        <Menu
          id={MENU_ID}
          visible={visible}
          onRequestClose={() => setVisible(false)}
        />
      )}
      marquee={
        marquee
          ? () => (
              <Marquee slug={marquee.location}>
                <div className="overflow-ellipsis type-mono-sm">
                  {marquee.text} &rarr;
                </div>
              </Marquee>
            )
          : null
      }
      newsletter={() =>
        !showNewsletterCTA ? null : (
          <NewsletterCTA
            id="newsletter"
            inputId={NEWSLETTER_ID}
            heading={newsletterTitle}
            appearance={newsletterAppearance}
            interests={newsletterInterests}
          />
        )
      }
      footer={() => (
        <Footer
          appearance={footerAppearance}
          copyrightOwner={copyrightOwner}
          copyrightYear={copyrightYear}
          heading={footerHeading}
          links={footerLinkGroups}
          privacy={privacyPolicyLink}
          terms={termsOfUseLink}
        />
      )}
      contact={({ visible, setVisible }) => (
        <ContactModal
          url={contactIframeSrc}
          visible={visible}
          onRequestClose={() => setVisible(false)}
        />
      )}
    />
  )
}

function LayoutStructure({
  children,
  contact,
  seo,
  marquee,
  navigation,
  menu,
  newsletter,
  footer,
}) {
  return (
    <ContactProvider>
      <MenuProvider>
        <div
          className={cx('layout', {
            'layout--marquee': !!marquee,
          })}
        >
          {seo ? seo() : null}
          {marquee ? marquee() : null}
          {navigation ? navigation() : null}
          <main id="content">{children}</main>
          {newsletter ? newsletter() : null}
          {footer ? footer() : null}
        </div>
        {menu ? <MenuConsumer>{menu}</MenuConsumer> : null}
        {contact ? <ContactConsumer>{contact}</ContactConsumer> : null}
      </MenuProvider>
    </ContactProvider>
  )
}
