import * as React from 'react'
import classnames from 'classnames'
import NewsletterManager from './newsletter-manager'
import { InputArrow } from '../icons'
import { useStaticTracking } from '../../hooks'

interface Props {
  /** Field `<label /> value` */
  label: string | React.ReactNode
  /** Form element id (a11y) */
  inputId: string
  /** Text `<input />`'s `placeholder` attribute */
  placeholder: string
  /** Variants for `default` and `huge` */
  appearance: 'default' | 'huge'
  /** Mailchimp interest ids */
  interests?: string[]
}

type State = {
  /** Field value */
  value: string
}

export default class NewsletterInput extends React.Component<Props, State> {
  static defaultProps = {
    label: 'Enter email address',
    placeholder: 'lauren.ipsum@gmail.com',
    appearance: 'default',
    interests: undefined,
  }

  shadow: React.Ref<HTMLDivElement>
  progress: React.Ref<HTMLDivElement>

  public constructor(props) {
    super(props)
    this.state = { value: '' }
    this.progress = React.createRef()
    this.shadow = React.createRef()
  }

  componentDidUpdate(_, prevState: State) {
    const { value } = this.state
    if (prevState.value !== value) {
      const w = this.shadow.current.clientWidth
      const isEmpty = value === ''
      this.progress.current.style.setProperty('--content-w', w + 'px')
      this.progress.current.style.setProperty(
        '--content-is-empty',
        isEmpty ? 0 : 1
      )
    }
  }

  public render() {
    const { value } = this.state
    const { label, inputId, placeholder, appearance, interests } = this.props
    const [onTrackFocus] = useStaticTracking({
      action: 'Focused Email Input',
      category: 'Newsletter Signup CTA',
    })
    const [onTrackSubmit] = useStaticTracking({
      action: 'Submitted Email Input',
      category: 'Newsletter Signup CTA',
    })
    const [onTrackBlur] = useStaticTracking({
      action: 'Blurred Email Input',
      category: 'Newsletter Signup CTA',
    })
    return (
      <NewsletterManager value={value} interests={interests}>
        {({ status, isValid, onSubmit, response }) => (
          <form
            onSubmit={e => {
              e.preventDefault()
              onTrackSubmit()
              onSubmit({ address: value })
            }}
          >
            <label
              className={classnames('newsletter-input', {
                [`newsletter-input--${status}`]: true,
                [`newsletter-input--${appearance}`]: appearance !== 'default',
                'newsletter-input--valid': isValid,
              })}
              htmlFor={inputId}
            >
              <p className="type-label-lg">{label}</p>
              <div className="newsletter-input__border">
                <div className="position-relative">
                  <input
                    required
                    type="email"
                    id={inputId}
                    value={value}
                    placeholder={placeholder}
                    data-invalid={!isValid}
                    onFocus={onTrackFocus}
                    onBlur={onTrackBlur}
                    className="newsletter-input__field"
                    onChange={({ currentTarget: { value } }) =>
                      this.setState({ value })
                    }
                  />
                  <div
                    aria-hidden
                    ref={this.shadow}
                    className="newsletter-input__shadow"
                  >
                    {value}
                  </div>
                  <div
                    aria-hidden
                    ref={this.progress}
                    className="newsletter-input__progress"
                  />
                  <button
                    type="submit"
                    disabled={!isValid || status === 'busy'}
                    className="newsletter-input__button"
                  >
                    <span className="sr-only">Submit</span>
                    <InputArrow />
                  </button>
                </div>
              </div>
            </label>
            <div className="newsletter-input__response child-my-0">
              {response ? (
                <p
                  aria-live="assertive"
                  dangerouslySetInnerHTML={{ __html: response.msg }}
                />
              ) : null}
            </div>
          </form>
        )}
      </NewsletterManager>
    )
  }
}
