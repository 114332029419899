// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-impact-index-tsx": () => import("./../src/pages/business-impact/index.tsx" /* webpackChunkName: "component---src-pages-business-impact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-styleguide-buttons-tsx": () => import("./../src/pages/styleguide/buttons.tsx" /* webpackChunkName: "component---src-pages-styleguide-buttons-tsx" */),
  "component---src-pages-styleguide-feature-label-tsx": () => import("./../src/pages/styleguide/feature-label.tsx" /* webpackChunkName: "component---src-pages-styleguide-feature-label-tsx" */),
  "component---src-pages-styleguide-forms-tsx": () => import("./../src/pages/styleguide/forms.tsx" /* webpackChunkName: "component---src-pages-styleguide-forms-tsx" */),
  "component---src-pages-styleguide-images-tsx": () => import("./../src/pages/styleguide/images.tsx" /* webpackChunkName: "component---src-pages-styleguide-images-tsx" */),
  "component---src-pages-styleguide-index-tsx": () => import("./../src/pages/styleguide/index.tsx" /* webpackChunkName: "component---src-pages-styleguide-index-tsx" */),
  "component---src-pages-styleguide-lists-tsx": () => import("./../src/pages/styleguide/lists.tsx" /* webpackChunkName: "component---src-pages-styleguide-lists-tsx" */),
  "component---src-pages-styleguide-type-tsx": () => import("./../src/pages/styleguide/type.tsx" /* webpackChunkName: "component---src-pages-styleguide-type-tsx" */),
  "component---src-pages-styleguide-wysiwyg-tsx": () => import("./../src/pages/styleguide/wysiwyg.tsx" /* webpackChunkName: "component---src-pages-styleguide-wysiwyg-tsx" */),
  "component---src-pages-team-tsx": () => import("./../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-marketing-page-tsx": () => import("./../src/templates/marketing-page.tsx" /* webpackChunkName: "component---src-templates-marketing-page-tsx" */)
}

