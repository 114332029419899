import * as React from 'react'

const Email = props => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22087 5.68677C6.97095 5.68677 0.53125 2.13534 0.53125 2.13534L0.53125 1.58077C0.53125 0.89362 1.10469 0.335937 1.81253 0.335937L14.6292 0.335937C15.3377 0.335937 15.9111 0.89362 15.9111 1.58077L15.9009 2.20318C15.9009 2.20318 9.53095 5.68677 8.22087 5.68677Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22087 7.39637C9.59111 7.39637 15.9009 4.06836 15.9009 4.06836L15.9111 11.5374C15.9111 12.2245 15.3377 12.7822 14.6292 12.7822L1.81253 12.7822C1.10533 12.7822 0.53125 12.2245 0.53125 11.5374L0.54149 4.06836C0.54085 4.06836 6.97095 7.39637 8.22087 7.39637Z"
      fill="currentColor"
    />
  </svg>
)

export default Email
