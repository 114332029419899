import React from 'react'
import ScrollLock from 'react-scrolllock'
import styles from './contact.module.scss'
import { CSSTransition } from 'react-transition-group'
import { Close } from '../icons'
import { IconButton } from '../button'
import cx from 'classnames'

function ModalTransition({
  visible,
  timeout = 1000,
  children,
  onEntered,
  onExited,
}) {
  return (
    <CSSTransition
      in={visible}
      timeout={timeout}
      classNames={styles}
      unmountOnExit
      onEntered={onEntered}
      onExited={onExited}
    >
      {() => children}
    </CSSTransition>
  )
}

export function ContactModal({ url, visible, onRequestClose }) {
  const [foreground, setForeground] = React.useState(false)
  return (
    <ModalTransition
      visible={visible}
      onEntered={() => setForeground(true)}
      onExited={() => setForeground(false)}
    >
      <div className={styles.container}>
        <ScrollLock isActive={foreground}>
          <div className={styles.window}>
            <div className={cx(styles.dismiss, 'container__row')}>
              <IconButton onClick={onRequestClose} onTouchEnd={onRequestClose}>
                <Close />
              </IconButton>
            </div>
            <div className={styles.content}>
              {!foreground ? null : (
                <iframe
                  className={styles.iframe}
                  src={url}
                  frameborder="0"
                  onmousewheel=""
                  style={{
                    background: 'transparent',
                    border: '1px solid #ccc',
                  }}
                ></iframe>
              )}
            </div>
          </div>
        </ScrollLock>
      </div>
    </ModalTransition>
  )
}

export default ContactModal
