import * as React from 'react'
import cx from 'classnames'
import { Arrow } from '../icons'

interface Props {
  children: React.ReactNode
  size?: 'default' | 'small'
  appearance?: 'default' | 'secondary'
  arrow?: boolean
  as?: any
  fill?: boolean
  href?: string
  className?: string
  [rest: string]: any
}

export default class Button extends React.Component<Props, {}> {
  static defaultProps = {
    appearance: 'default',
    size: 'default',
    arrow: false,
    as: undefined,
    fill: false,
    href: undefined,
    className: undefined,
  }

  static AnimatedArrow = () => (
    <span aria-hidden className="button__icon">
      <span className="button__icon-view">
        <Arrow />
        <Arrow />
      </span>
    </span>
  )

  public render() {
    const {
      as: custom,
      appearance,
      size,
      arrow,
      href,
      fill,
      className,
      children,
      ...props
    } = this.props
    const Element = custom ? custom : href ? 'a' : 'button'
    return (
      <Element
        {...(Element === 'button' ? { type: 'button' } : {})}
        {...(Element === 'a' ? { href } : {})}
        {...props}
        className={cx(
          'button',
          {
            [`button--${appearance}`]: appearance !== 'default',
            [`button--${size}`]: size !== 'default',
            'button--fill': fill,
            'button--icon': !!arrow,
          },
          className
        )}
      >
        <span className="button__bg-wipe" />
        <span className="button__content">{children}</span>
        {arrow ? <Button.AnimatedArrow /> : null}
      </Element>
    )
  }
}
