import React, { useState } from 'react'

export const MenuContext = React.createContext({})
export const MenuConsumer = MenuContext.Consumer

export function MenuProvider({ children }) {
  const [visible, setVisible] = useState(false)
  return (
    <MenuContext.Provider
      value={{
        visible,
        setVisible,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}
