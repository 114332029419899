import * as React from 'react'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import ScrollLock from 'react-scrolllock'
// import FocusTrap from 'focus-trap-react'
import { Link } from '../link'
import { Logo, Close } from '../icons'
import { IconButton } from '../button'
import { LinkGroup } from '../../data/link-group'
import { LinkItem } from '../../data/link'
import { transform as toGlobals } from '../../data/globals'
import * as styles from './menu.module.scss'
import { isExternalLink } from '../../utils/external-link'
// import { ContactConsumer } from '../../context/contact'

interface ExternalProps {
  id: string
  visible: boolean
  onRequestClose: () => any
}

interface DataProps {
  primary: LinkItem[]
  groups: LinkGroup[]
}

function Menu(props: ExternalProps & DataProps) {
  const [trapFocus, setTrapFocus] = React.useState(false)
  const enableFocusTrap = () => setTrapFocus(true)
  const disableFocusTrap = () => setTrapFocus(false)
  const dismiss = () => props.onRequestClose && props.onRequestClose()
  const { id, visible, onRequestClose, primary = [], groups = [] } = props
  return (
    <CSSTransition
      in={visible}
      timeout={1000}
      classNames={styles}
      onEntered={enableFocusTrap}
      onExited={disableFocusTrap}
      unmountOnExit
    >
      {() => (
        <aside className={styles.menu}>
          <ScrollLock isActive={trapFocus}>
            <div id={id} className={styles.menu__content}>
              <div className="container container--grid">
                <div className="container__row">
                  <div className={styles.menu__grid}>
                    <div className={styles.menu__top}>
                      <Link onClick={dismiss} to="/">
                        <Logo />
                      </Link>
                      <IconButton
                        appearance="secondary"
                        onClick={onRequestClose}
                      >
                        <Close />
                      </IconButton>
                    </div>
                    <div className={styles.menu__primary}>
                      <PrimaryList
                        onRequestClose={onRequestClose}
                        title="Navigation"
                        list={primary}
                      />
                    </div>
                    <div className={styles.menu__secondaries}>
                      {groups.map(group => (
                        <MenuList
                          onRequestClose={onRequestClose}
                          key={group.title}
                          title={group.title}
                          list={group.links}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollLock>
          <div aria-hidden className={styles.menu__gradient} />
        </aside>
      )}
    </CSSTransition>
  )
}

function PrimaryList({
  list,
  title,
  onRequestClose,
}: {
  list: LinkItem[]
  title: string
  onRequestClose: () => any
  primary?: boolean
}) {
  return (
    <nav className={cx(styles.menu__list, styles['menu__list--primary'])}>
      <p className={styles.menu__list__title}>{title}</p>
      <ul>
        {list.map(link => (
          <li key={link.text}>
            <Link
              to={link.location}
              onClick={onRequestClose}
              className="type-menu-lg"
              target={isExternalLink(link.location) ? '_blank' : undefined}
              rel={
                isExternalLink(link.location)
                  ? 'noopener noreferrer'
                  : undefined
              }
            >
              {link.text}
            </Link>
          </li>
        ))}
        {/* <li>
          <ContactConsumer>
            {({ setVisible }) => (
              <button
                onClick={() => {
                  setVisible(true)
                  setTimeout(onRequestClose, 1100) // behind form
                }}
                className="type-menu-lg"
              >
                Contact
              </button>
            )}
          </ContactConsumer>
        </li> */}
      </ul>
    </nav>
  )
}

function MenuList({
  list,
  title,
  onRequestClose,
}: {
  list: LinkItem[]
  title: string
  onRequestClose: () => any
  primary?: boolean
}) {
  return (
    <nav className={styles.menu__list}>
      <p className={styles.menu__list__title}>{title}</p>
      <ul>
        {list.map(link => (
          <li key={link.text}>
            <Link
              to={link.location}
              onClick={onRequestClose}
              target={isExternalLink(link.location) ? '_blank' : undefined}
              rel={
                isExternalLink(link.location)
                  ? 'noopener noreferrer'
                  : undefined
              }
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default function MenuWithQuery(props: ExternalProps) {
  return (
    <StaticQuery
      query={graphql`
        query {
          globals: allContentfulSettingsDesignerFund(limit: 1) {
            edges {
              node {
                ...GlobalsFields
              }
            }
          }
        }
      `}
      render={data => {
        const [globals] = data.globals.edges.map(({ node }) => toGlobals(node))
        return (
          <Menu {...props} primary={globals.navigation} groups={globals.menu} />
        )
      }}
    />
  )
}
