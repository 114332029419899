import * as React from 'react'
import cx from 'classnames'
import { Link } from '../link'
import { Logo, Dots } from '../icons'
import { LinkItem } from '../../data/link'
import styles from './navigation.module.scss'
import { isExternalLink } from '../../utils/external-link'
import { ContactConsumer } from '../../context/contact'
import { useStaticTracking } from '../../hooks'
import { MenuConsumer } from '../../context/menu'

const TEXT_CLASS = 'type-footer-link'

interface Props {
  primary: LinkItem[]
  contact: boolean
}

interface State {
  scrollPosition: number
  scrollDirection: 'UP' | 'DOWN'
  navVisible: boolean
}

export default class Navigation extends React.Component<Props, State> {
  header: React.RefObject<HTMLHeadElement>

  static defaultProps = {
    contact: true,
  }

  constructor(props) {
    super(props)
    this.header = React.createRef()
    // prevent render flash with initial inexact amount
    this.state = {
      scrollPosition: 0,
      scrollDirection: 'UP',
      navVisible: true,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollPosition)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollPosition)
  }

  updateScrollPosition = () => {
    const { pageYOffset, innerHeight } = window
    if (pageYOffset < innerHeight) {
      return this.setState({ scrollDirection: 'UP' })
    }
    const { scrollPosition } = this.state
    const dir = scrollPosition >= pageYOffset ? 'UP' : 'DOWN'
    this.setState({ scrollDirection: dir, scrollPosition: pageYOffset })
  }

  public render() {
    const { primary, contact } = this.props
    const { scrollDirection } = this.state
    const [onTrackMenu] = useStaticTracking({
      action: 'Open Navigation Menu',
      category: 'Global Navigation',
    })
    return (
      <>
        <header
          className={cx(styles.navigation, {
            [styles.scrolling]: scrollDirection === 'DOWN',
          })}
        >
          <div className="container container--grid">
            <div className="container__row">
              <div className={styles.navigation__content}>
                <div className={styles.navigation__logoBox}>
                  <SkipToContent />
                  <div className={styles.navigation__logo}>
                    <Link to="/">
                      <Logo />
                    </Link>
                  </div>
                </div>
                <nav className={styles.navigation__nav}>
                  <ul className={styles.navigation__links}>
                    {primary.map(item => (
                      <li key={item.text} className={styles.navigation__link}>
                        <Link
                          to={item.location}
                          className={TEXT_CLASS}
                          target={
                            isExternalLink(item.location) ? '_blank' : undefined
                          }
                          rel={
                            isExternalLink(item.location)
                              ? 'noopener noreferrer'
                              : undefined
                          }
                        >
                          {item.text}
                        </Link>
                      </li>
                    ))}
                    {!contact ? null : (
                      <li className={styles.navigation__link}>
                        <ContactConsumer>
                          {({ setVisible }) => (
                            <button
                              onClick={() => setVisible(true)}
                              className={TEXT_CLASS}
                            >
                              Contact
                            </button>
                          )}
                        </ContactConsumer>
                      </li>
                    )}
                  </ul>
                  <MenuConsumer>
                    {({ visible, setVisible }) => (
                      <button
                        className={styles.navigation__menuBtn}
                        data-expanded={visible}
                        aria-haspopup={true}
                        onClick={() => {
                          setVisible(true)
                          onTrackMenu()
                        }}
                        aria-controls={'#navigation-menu'}
                        type="button"
                      >
                        <span className="sr-only">Menu</span>
                        <Dots />
                      </button>
                    )}
                  </MenuConsumer>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

function SkipToContent() {
  return (
    <a
      className={cx(styles.skip, TEXT_CLASS, 'sr-only sr-only--focusable')}
      href="#content"
    >
      Skip to content
    </a>
  )
}
