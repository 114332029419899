import * as React from 'react'
import IconButton from './icon-button'

interface Props {
  direction?: 'previous' | 'next'
  [rest: string]: any
}

const PaginationButton = ({ direction = 'next', ...props }: Props) => (
  <IconButton {...props}>
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={direction === 'previous' ? { transform: 'rotate(180deg)' } : {}}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.48711 0.659181L12.6966 5.1732L8.47326 9.35546L7.76962 8.64491L10.7845 5.65934L-8.74256e-07 5.65934L-6.9941e-07 4.65934L10.8501 4.65934L7.75577 1.34119L8.48711 0.659181Z"
        fill="currentColor"
      />
    </svg>
  </IconButton>
)

export default PaginationButton
