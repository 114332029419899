import * as React from 'react'
import cx from 'classnames'

export default function DFBIButton({
  as: custom,
  appearance = 'default',
  size = 'default',
  arrow = false,
  href,
  fill = false,
  max = false,
  className,
  children,
  ...props
}: {
  children: React.ReactNode
  size?: 'default' | 'small'
  appearance?: 'default' | 'secondary'
  arrow?: boolean
  as?: any
  fill?: boolean
  max?: boolean
  href?: string
  className?: string
  [rest: string]: any
}) {
  const Element = custom ? custom : href ? 'a' : 'button'
  return (
    <Element
      {...(Element === 'button' ? { type: 'button' } : {})}
      {...(Element === 'a' ? { href } : {})}
      {...props}
      className={cx(
        'dfbi-button',
        {
          [`dfbi-button--${appearance}`]: appearance !== 'default',
          [`dfbi-button--${size}`]: size !== 'default',
          [`dfbi-button--max`]: max,
          'dfbi-button--fill': fill,
        },
        className
      )}
    >
      {children}
    </Element>
  )
}
