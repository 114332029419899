import * as React from 'react'
import { Link } from '../link'
import classnames from 'classnames'

interface Props {
  /** Link's `to` prop */
  link: string
  /** React children */
  children: React.ReactChildren
  /** Animation loop duration in `ms` */
  duration?: number
}

type State = {
  count: number
}

const calculateItemsInView = (ref: HTMLDivElement) => {
  const [single, total] = [ref.clientWidth, ref.parentNode.clientWidth]
  // ceil in order to ensure there's never a shortage
  return Math.floor(total / single) + 1
}

// TODO: Store the shadow and window sizes in state
// when they both exist, replace the guestimate count
// which prevents initail render flash with exact number
// calculate the shadow/window in render() which will be
// re-triggered whenever stateful values change
export default class Marquee extends React.Component<Props, State> {
  static defaultProps = {
    duration: undefined,
  }

  shadow: React.RefObject<HTMLDivElement>
  throttle: NodeJS.Timeout

  constructor(props) {
    super(props)
    this.shadow = React.createRef()
    // prevent render flash with initial inexact amount
    this.state = { count: null }
  }

  componentDidMount() {
    this.update()
    window.addEventListener('resize', this.update)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update)
  }

  update() {
    clearTimeout(this.throttle)
    this.throttle = setTimeout(() => {
      if (this.shadow && this.shadow.current) {
        const { current } = this.shadow
        const inView = calculateItemsInView(current)
        this.setState({ count: inView * 2 })
      }
    }, 500)
  }

  public render() {
    const { duration, link, children, ...props } = this.props
    const { count } = this.state
    React.Children.only(children)
    return (
      <Link to={link} target="_blank" rel="noopener noreferrer" {...props}>
        <div className="marquee__full">
          <div className="marquee__full-shadow" ref={this.shadow}>
            <div className="marquee__full-element">{children}</div>
          </div>
          <div className="marquee__full-overflow">
            <div
              className={classnames('marquee__full-elements', {
                'is-animated': count !== null,
              })}
            >
              {Array.from({ length: count === null ? 100 : count }).map(
                (_, idx) => (
                  <div
                    className="marquee__full-element"
                    aria-hidden={!!idx}
                    key={idx}
                  >
                    {children}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </Link>
    )
  }
}
