import * as React from 'react'
import cx from 'classnames'

interface Props {
  children: React.ReactNode
  appearance?: 'default' | 'secondary' | 'tertiary'
  className?: string
  as?: any
  href?: string
  [rest: string]: any
}

export default class IconButton extends React.Component<Props, {}> {
  static defaultProps = {
    appearance: 'default',
    className: undefined,
    as: undefined,
    href: undefined,
  }

  public render() {
    const {
      as: custom,
      href,
      className,
      children,
      appearance,
      ...props
    } = this.props
    const Element = custom ? custom : href ? 'a' : 'button'
    return (
      <Element
        {...(Element === 'button' ? { type: 'button' } : {})}
        {...props}
        className={cx(
          'icon-button',
          {
            [`icon-button--${appearance}`]: appearance !== 'default',
          },
          className
        )}
      >
        {children}
      </Element>
    )
  }
}
