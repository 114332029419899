import * as React from 'react'

const InputArrow = props => (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3121 0.996094L27.5556 13.4785L16.2714 25.0474L14.1238 22.9526L21.9415 14.9377H0.5V11.9377H22.1301L14.083 3.0039L16.3121 0.996094Z"
    />
  </svg>
)

export default InputArrow
