import * as React from 'react'

const Logo = props => (
  <svg
    width="777"
    height="117"
    viewBox="0 0 777 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M121.899 21.5H151.299C173.499 21.5 187.799 36.3 187.799 58C187.799 80.2 173.399 95.3 151.099 95.3H121.899V21.5ZM150.399 82.4C162.899 82.4 169.699 71.2 169.699 58C169.699 44.6 163.299 34.4 150.599 34.4H139.499V82.5H150.399V82.4Z"
      fill="currentColor"
    />
    <path
      d="M242.799 73.1H206.699C206.799 80.2 211.699 84.6 218.699 84.6C223.799 84.6 227.599 82.1 229.999 77.3L242.299 82.9C238.099 91.4 229.299 96.5 218.499 96.5C201.299 96.5 190.899 86.6 190.899 69.8C190.899 52.7 201.099 42 217.299 42C233.599 42 242.799 51.5 242.799 68V73.1ZM226.599 63.3C226.599 55.9 223.199 52 216.899 52C210.499 52 206.799 56.4 206.599 63.8H226.599V63.3Z"
      fill="currentColor"
    />
    <path
      d="M244.8 84.1001L254.5 76.8001C258.1 82.4001 263.8 85.2001 270.9 85.2001C276.3 85.2001 279 83.5001 279 80.6001C279 77.5001 277.3 76.3001 271.6 75.5001L264.1 74.5001C252.9 72.8001 247.6 67.3001 247.6 58.5001C247.6 48.4001 256.1 42.1001 270.5 42.1001C281 42.1001 289.8 45.9001 294.1 52.4001L285.5 60.2001C281.7 55.8001 276.5 53.2001 270 53.2001C265 53.2001 262.7 54.6001 262.7 57.5001C262.7 60.0001 264.3 61.3001 268.8 62.0001L278.1 63.5001C289.2 65.1001 293.6 70.6001 293.6 79.5001C293.6 90.1001 285.2 96.7001 270.6 96.7001C259.1 96.6001 249.8 92.3001 244.8 84.1001Z"
      fill="currentColor"
    />
    <path
      d="M299.199 20.6997H316.599V35.8997H299.199V20.6997ZM299.399 43.2997H316.099V95.2997H299.399V43.2997Z"
      fill="currentColor"
    />
    <path
      d="M371.899 45.1999C369.099 45.1999 366.799 45.7999 365.099 47.0999C368.899 50.0999 371.099 54.3999 371.099 59.4999C371.099 70.0999 362.099 77.1999 347.399 77.1999C343.299 77.1999 339.799 76.6999 336.699 75.5999C335.699 76.2999 335.099 77.1999 335.099 78.3999C335.099 80.9999 337.299 82.6999 342.299 82.6999H356.899C369.599 82.6999 375.999 87.9999 375.999 97.8999C375.999 109.8 365.599 116.9 345.999 116.9C328.699 116.9 320.399 111.8 320.399 104.4C320.399 100.2 323.399 96.8999 329.299 94.9999C324.499 93.0999 322.199 89.6999 322.199 85.1999C322.199 79.5999 325.599 75.4999 331.599 73.2999C326.399 70.1999 323.499 65.3999 323.499 59.3999C323.499 48.8999 332.699 41.9999 347.299 41.9999C351.899 41.9999 355.799 42.5999 359.299 43.8999C361.099 38.0999 366.199 34.3999 374.399 34.3999C375.099 34.3999 375.799 34.3999 376.599 34.4999V45.5999C374.899 45.3999 373.499 45.1999 371.899 45.1999ZM338.699 96.4999C337.299 96.4999 336.099 96.3999 334.999 96.2999C333.499 97.6999 332.899 99.2999 332.899 101.2C332.899 105.8 338.199 107.8 346.799 107.8C357.599 107.8 362.099 105.4 362.099 101.7C362.099 98.3999 359.799 96.6999 355.099 96.6999L338.699 96.4999ZM355.499 59.4999C355.499 54.1999 352.299 50.6999 347.499 50.6999C342.599 50.6999 339.399 54.1999 339.399 59.4999C339.399 64.7999 342.599 68.6999 347.499 68.6999C352.299 68.5999 355.499 64.8999 355.499 59.4999Z"
      fill="currentColor"
    />
    <path
      d="M380.8 43.3H396.8V53.3C400.4 45.7 406.2 42 414.1 42C425.3 42 431.1 49.2 431.1 63.1V95.3H414.5V65.3C414.5 58.1 412 54.7 406.7 54.7C400.9 54.7 397.7 59.2 397.7 67.2V95.3H381V43.3H380.8Z"
      fill="currentColor"
    />
    <path
      d="M488.5 73.1H452.4C452.5 80.2 457.4 84.6 464.4 84.6C469.5 84.6 473.3 82.1 475.7 77.3L488 82.9C483.8 91.4 475 96.5 464.2 96.5C447 96.5 436.6 86.6 436.6 69.8C436.6 52.7 446.8 42 463 42C479.3 42 488.5 51.5 488.5 68V73.1ZM472.3 63.3C472.3 55.9 468.9 52 462.6 52C456.2 52 452.5 56.4 452.3 63.8H472.3V63.3Z"
      fill="currentColor"
    />
    <path
      d="M527.4 42.1001H528.4V58.0001C527.4 57.9001 526.5 57.9001 525.3 57.9001C520.8 57.9001 517.5 59.0001 515.2 61.0001C511.9 64.1001 511 68.8001 511 74.1001V95.3001H494.3V43.3001H510V56.7001C512.7 47.4001 518.5 42.1001 527.4 42.1001Z"
      fill="currentColor"
    />
    <path
      d="M578.499 34.8V51.9H609.599V65H578.499V95.4H560.999V21.5H611.499V34.8H578.499Z"
      fill="currentColor"
    />
    <path
      d="M614.199 76.9998V43.2998H630.799V74.0998C630.799 80.4998 633.399 83.8998 638.299 83.8998C643.699 83.8998 646.999 79.5998 646.999 73.0998V43.2998H663.699V95.2998H647.799V85.7998C645.299 92.3998 639.199 96.4998 631.599 96.4998C619.399 96.5998 614.199 89.1998 614.199 76.9998Z"
      fill="currentColor"
    />
    <path
      d="M668.6 43.3H684.6V53.3C688.2 45.7 694 42 701.9 42C713.1 42 718.9 49.2 718.9 63.1V95.3H702.3V65.3C702.3 58.1 699.8 54.7 694.5 54.7C688.7 54.7 685.5 59.2 685.5 67.2V95.3H668.8V43.3H668.6Z"
      fill="currentColor"
    />
    <path
      d="M723.1 69.5999C723.1 53.0999 731.8 42.0999 744.8 42.0999C751.7 42.0999 756.9 44.8999 759.5 50.2999V19.8999H776.1V95.2999H760.3V86.3999C757.7 92.5999 751.5 96.5999 743.9 96.5999C731.4 96.5999 723.1 85.7999 723.1 69.5999ZM759.7 71.7999V67.2999C759.7 58.4999 756.2 53.5999 750.2 53.5999C743.9 53.5999 740.2 59.1999 740.2 69.1999C740.2 78.9999 744 84.9999 750.4 84.9999C756.1 84.9999 759.7 79.9999 759.7 71.7999Z"
      fill="currentColor"
    />
    <path
      d="M3.29972 56.4C5.99972 55.1 10.1997 53.8 12.7997 53.3C19.1997 52 29.3997 52 37.9997 52C37.9997 56 38.1997 61.1 37.9997 65.1C26.6997 65.1 19.1997 66.2 11.1997 71.5C1.69972 78.1 -0.300276 88.3 -0.000275509 96.6C0.699724 108.3 8.59973 116.9 20.9997 116.9C40.6997 116.9 53.9997 105.4 53.9997 80.9C64.1997 80.9 73.1997 80.5 78.9997 77.4C85.3997 73.9 88.8997 66.1 86.2997 59.5C83.5997 61.1 80.2997 62.6 74.5997 63.9C70.8997 64.8 63.9997 65.7 54.1997 65.7C54.1997 63.7 53.9997 54.2 54.1997 52.2C71.9997 52.2 81.9997 46 86.6997 38.9C90.1997 33.8 92.4997 27.8 92.1997 18.6C91.4997 9.5 84.4997 0 70.8997 0C50.1997 0 38.1997 13.9 38.1997 36.9C27.1997 36.9 16.4997 36.4 10.2997 39.8C4.09972 43.3 1.69972 50.7 3.29972 56.4ZM54.0997 36.9C54.0997 20.1 58.9997 6 71.3997 6C79.7997 6 85.0997 10.9 85.0997 18.4C85.0997 32.1 70.8997 36.9 54.0997 36.9ZM15.5997 83.6C21.7997 80.3 28.8997 80.5 38.0997 80.5C37.9997 96 34.4997 111 20.6997 111C12.9997 111 6.59972 106.3 6.59972 97.3C6.79972 90.9 10.5997 86 15.5997 83.6Z"
      className="logo-glyph"
    />
  </svg>
)

export default Logo
