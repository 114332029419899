import * as React from 'react'
import { Link } from '../link'
import { AnimatedMarquee } from '.'

const Marquee = ({ children, slug, ...props }) => (
  <aside className="marquee">
    <Link
      to={slug}
      target="_blank"
      rel="noopener noreferrer"
      className="marquee__mobile"
    >
      {children}
    </Link>
    <AnimatedMarquee link={slug} {...props}>
      {children}
    </AnimatedMarquee>
  </aside>
)

export default Marquee
