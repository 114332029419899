import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'
import styles from './marquee-v2.module.scss'

export default function Marquee({
  children,
  className = undefined,
  reverse = false,
  pauseOnHover = true,
  duration = undefined,
  ...props
}) {
  React.Children.only(children)
  const [count, ref, width] = useMarquee()
  const [child] = React.Children.toArray(children)
  return (
    <div
      {...props}
      className={cx(
        styles.marquee,
        {
          [styles.animated]: count !== null,
          [styles.reversed]: !!reverse,
          [styles.pauseOnHover]: !!pauseOnHover,
        },
        className
      )}
    >
      <div ref={ref} className={styles.marquee__measure} aria-hidden>
        {children}
      </div>
      <div className={styles.marquee__spacer}>{children}</div>
      <div className={styles.marquee__overflow}>
        <div
          className={styles.marquee__elements}
          style={{ width, animationDuration: duration }}
          aria-hidden
        >
          {Array.from({ length: count }).map((_, idx) =>
            React.cloneElement(child, {
              ...child.props,
              key: `marqueev2-${idx}`,
              style: { ...child.props.style, flex: '0 0 auto' },
            })
          )}
        </div>
      </div>
    </div>
  )
}

const getWidth = el => el.clientWidth

function fillContainer(el) {
  // get the individual element width and the container width as basis
  // for inFullView calculation
  const [single, total] = [getWidth(el), getWidth(el.parentNode)]
  // the floored number of elements completely visible in the container
  const inFullView = Math.floor(total / single)
  // FillGaps: add one so there is never an empty space left out by the
  // inFullView calculation e.g. 100px card in 150px contaienr. There
  // would be 1 in full view but then a 50px gap
  const fillGaps = 1
  // accountForAnimation: The animation pans the container of the repeated
  // elements across the X axis equal to the width of a single element
  // in order to make sure there are no gaps whilst animating we'll need
  // an additional 1 extra to make up for the one being animted offscreen.
  const accountForAnimation = 1
  // combine & return
  return inFullView + fillGaps + accountForAnimation
}

function useMarquee() {
  const ref = useRef()
  const [count, setCount] = useState(null)

  useEffect(() => {
    let throttle

    function onUpdate() {
      clearTimeout(throttle)
      if (ref && ref.current) {
        throttle = setTimeout(() => setCount(fillContainer(ref.current)), 500)
      }
    }

    onUpdate()
    window.addEventListener('resize', onUpdate)

    return () => {
      clearTimeout(throttle)
      window.removeEventListener('resize', onUpdate)
    }
  }, [ref])

  return [count, ref, ref.current ? getWidth(ref.current) : null]
}
