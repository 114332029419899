import * as React from 'react'

const ExternalLink = props => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.654297 12.1392L12.6543 0.63916L13.3462 1.36115L1.3462 12.8611L0.654297 12.1392Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 1.5H4V0.5H13.5V10H12.5V1.5Z"
      fill="currentColor"
    />
  </svg>
)

export default ExternalLink
