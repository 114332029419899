import * as React from 'react'

const Facebook = props => (
  <svg
    width="9"
    height="18"
    viewBox="0 0 9 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09629 17.7915L6.0963 9.32579L8.49919 9.32579L8.81763 6.40846L6.0963 6.40846L6.10038 4.94831C6.10038 4.18742 6.17471 3.77972 7.29842 3.77972L8.80062 3.77972L8.80062 0.862062L6.39738 0.862062C3.51071 0.862061 2.49468 2.27728 2.49468 4.65723L2.49468 6.40879L0.695312 6.40879L0.695311 9.32612L2.49468 9.32612L2.49467 17.7915L6.09629 17.7915Z"
      fill="currentColor"
    />
  </svg>
)

export default Facebook
