import * as React from 'react'
import styled from 'styled-components'
import { Flower } from '../icons'
import { NewsletterInput } from '.'

const StyledNewsletterCTA = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  /* min-height: 80vh; */
  height: auto;
  padding: 80px 16px 46px;
  background-color: ${({ appearance }) =>
    appearance === 'business-impact'
      ? 'var(--color-n85)'
      : 'var(--color-mint)'};

  & > svg {
    margin-bottom: 16px;
    animation-duration: 30s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-iteration-count: infinite;
    animation-name: pulse-delay-between;
  }

  h2 {
    max-width: 320px;
    margin: 0 auto;
  }

  form {
    margin-top: 50px;
    max-width: 530px;
    width: 100%;
  }

  @media screen and (min-width: 420px) {
    h2 {
      max-width: 360px;
    }
  }

  @media screen and (min-width: 540px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 768px) {
    padding-top: 110px;
    padding-bottom: 110px;

    & > svg {
      margin-bottom: 24px;
    }
    h2 {
      padding-left: 16px;
      padding-right: 16px;
      max-width: 784px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
`

const StyledBizHeading = styled.h2`
  padding: 0 !important;
  width: 80%;
  max-width: 100% !important;
  font-size: 5vw;
  font-family: 'Favorit Extended';
  font-weight: 400;
  line-height: 104.96%;
  text-align: center;
  letter-spacing: -0.01em;

  @media screen and (min-width: 768px) {
    font-size: 4vw;
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    max-width: 870px !important;
    font-size: ${53 / 16}rem;
  }
`

export default function NewsletterCTA({
  heading,
  label,
  inputId,
  placeholder,
  appearance = 'default',
  interests,
  id = undefined,
}: {
  /** Section heading */
  heading: string | React.ReactNode
  /** Field `<label /> value` */
  label?: string | React.ReactNode
  /** Form element id (a11y) */
  inputId: string
  /** Text `<input />`'s `placeholder` attribute */
  placeholder?: string
  /** Style presets */
  appearance?: 'default' | 'business-impact'
  /** Mailchimp interest ids */
  interests?: string[]
  /** HTML id attribute */
  id?: string
}) {
  return (
    <StyledNewsletterCTA id={id} appearance={appearance}>
      <Flower />
      {appearance === 'business-impact' ? (
        <StyledBizHeading>{heading}</StyledBizHeading>
      ) : (
        <h2 className="type-h-xl">{heading}</h2>
      )}
      <NewsletterInput
        label={label}
        inputId={inputId}
        placeholder={placeholder}
        interests={interests}
      />
    </StyledNewsletterCTA>
  )
}
