import * as React from 'react'

const Twitter = props => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58996 2.98943L5.61462 3.39325L5.20364 3.3438C3.70768 3.15425 2.40076 2.51144 1.29112 1.43185L0.748631 0.896179L0.608898 1.29175C0.312993 2.17356 0.502043 3.10481 1.11851 3.73113C1.44729 4.07726 1.37332 4.12671 0.806167 3.92068C0.608898 3.85475 0.436287 3.8053 0.419848 3.83003C0.362311 3.88771 0.55958 4.63766 0.715752 4.93434C0.929461 5.3464 1.3651 5.75021 1.84183 5.98921L2.24459 6.17875L1.76786 6.187C1.30756 6.187 1.29112 6.19524 1.34044 6.3683C1.50483 6.90397 2.15418 7.47261 2.8775 7.71985L3.38711 7.89291L2.94326 8.15663C2.28569 8.53572 1.51305 8.74999 0.740411 8.76647C0.37053 8.77472 0.0664058 8.80768 0.0664058 8.8324C0.0664058 8.91481 1.06919 9.37632 1.65278 9.55762C3.40355 10.0933 5.4831 9.86255 7.04482 8.94778C8.15446 8.29673 9.26411 7.00287 9.78194 5.75021C10.0614 5.08268 10.3409 3.86299 10.3409 3.27787C10.3409 2.89878 10.3655 2.84933 10.8258 2.39607C11.0971 2.13235 11.3519 1.84391 11.4012 1.7615C11.4834 1.60492 11.4752 1.60492 11.056 1.74502C10.3573 1.99225 10.2587 1.95929 10.6039 1.58844C10.8587 1.32472 11.1628 0.846732 11.1628 0.706633C11.1628 0.681909 11.0395 0.723115 10.8998 0.797285C10.7518 0.879697 10.4231 1.00331 10.1765 1.07748L9.73262 1.21758L9.32986 0.945626C9.10793 0.797285 8.79559 0.632462 8.6312 0.583016C8.212 0.467639 7.57087 0.484122 7.19277 0.61598C6.16533 0.986832 5.51598 1.9428 5.58996 2.98943Z"
      fill="currentColor"
    />
  </svg>
)

export default Twitter
