import * as React from 'react'

const Arrow = props => (
  <svg
    width="31"
    height="15"
    viewBox="0 0 31 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4833 0.298302L30.7133 6.77983C31.0956 7.17757 31.0956 7.82243 30.7133 8.22017L24.4833 14.7017C24.101 15.0994 23.4812 15.0994 23.0988 14.7017C22.7165 14.304 22.7165 13.6591 23.0988 13.2614L27.6577 8.51847L7.44709e-07 8.51848L5.66633e-07 6.48153L27.6577 6.48153L23.0988 1.73864C22.7165 1.3409 22.7165 0.696041 23.0988 0.298302C23.4811 -0.0994368 24.101 -0.0994369 24.4833 0.298302Z"
      fill="currentColor"
    />
  </svg>
)

export default Arrow
