import * as React from 'react'

const Dots = props => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="8" height="8" rx="4" fill="currentColor" />
    <rect x="11" width="8" height="8" rx="4" fill="currentColor" />
    <rect y="11" width="8" height="8" rx="4" fill="currentColor" />
    <rect x="11" y="11" width="8" height="8" rx="4" fill="currentColor" />
  </svg>
)

export default Dots
