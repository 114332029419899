import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
// TODO: Add OutboundLink around the <a> type?
// import { OutboundLink } from 'gatsby-plugin-google-analytics'

interface Props {
  children: React.ReactNode
  to: string
  activeClassName?: string
  [other: string]: any
}

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
export const Link = ({ children, to, activeClassName, ...other }: Props) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  const file = /\.[0-9a-z]+$/i.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal && !file) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}
