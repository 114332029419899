import * as React from 'react'
import cx from 'classnames'
import { LogoGlyph } from '../icons'
import { Link } from '../link'
import { LinkItem } from '../../data/link'
import { LinkGroup } from '../../data/link-group'
import { isExternalLink } from '../../utils/external-link'

export default function Footer({
  copyrightOwner,
  copyrightYear,
  heading,
  links,
  privacy,
  terms,
  appearance = 'default',
}: {
  /** Section heading */
  heading: string | React.ReactNode
  /** Name of the copyright holder */
  copyrightOwner: string
  /** The copyright year */
  copyrightYear: number
  /** A list of lists of links */
  links: LinkGroup[]
  /** Privacy policy link */
  privacy: LinkItem
  /** Terms of use link */
  terms: LinkItem
  /** Style presets */
  appearance?: 'default' | 'business-impact'
}) {
  return (
    <footer
      className={cx('footer', {
        [`footer--${appearance}`]: appearance !== 'default',
      })}
    >
      <div className="container container--grid">
        <div className="footer__links">
          {links.map(group => (
            <FooterList
              key={group.title}
              title={group.title}
              list={group.links}
            />
          ))}
        </div>
        <div className="footer__about">
          <div>
            <LogoGlyph className="display-block footer__about-logo" />
            <h2 className="display-block footer__about-heading">{heading}</h2>
          </div>
          <div className="footer__about-copyright">
            <span>
              &copy; {copyrightYear} {copyrightOwner}
            </span>
            <Link to={terms.location}>{terms.text}</Link>
            <Link to={privacy.location}>{privacy.text}</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

function FooterList({ list, title }: { list: LinkItem[]; title: string }) {
  return (
    <nav className="footer-list">
      <p className="type-label-md">{title}</p>
      <ul className="footer-list__items list-reset">
        {list.map(link => (
          <li key={link.location} className="type-footer-link">
            <Link
              to={link.location}
              target={isExternalLink(link.location) ? '_blank' : undefined}
              rel={
                isExternalLink(link.location)
                  ? 'noopener noreferrer'
                  : undefined
              }
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
