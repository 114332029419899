import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Image } from './types'
import { ContentfulAsset } from './types/contentful'
import imageFallback from '../images/fallback.png'

export type ContentfulTypeResource = {
  /** UUID format */
  id: string
  /** Link to the Resource (short text) */
  url: string
  /** Resource title (short text) */
  title: string
  /** A short description (long text, plain) */
  description: {
    /** Assumed plain-text */
    description: string
  }
  /** The carousel image */
  image: ContentfulAsset
  /** Category reference */
  category: {
    id: string
    name: string
  }
  /** Date created */
  createdAt: string
}

export type Resource = {
  /** UUID format */
  id: string
  /** Link to the Resource (short text) */
  url: string
  /** Resource title (short text) */
  title: string
  category: {
    /** Category display name */
    name: string
    /** Category UUID */
    id: string
  }
  /** A short description (long text, plain) */
  description: string
  /** The carousel image */
  image: Image
  /** Date created */
  createdAt: Date
}

export const fragment = graphql`
  fragment ResourceFields on ContentfulTypeResource {
    id
    url
    title
    description {
      description
    }
    category {
      id
      name
    }
    image {
      id
      description
      file {
        url
      }
    }
    createdAt
  }
`

export const transform = (node: ContentfulTypeResource): Resource => ({
  id: node.id,
  url: get(node, 'url', ''),
  title: get(node, 'title', ''),
  category: {
    name: get(node, 'category.name', ''),
    id: get(node, 'category.id', ''),
  },
  description: get(node, 'description.description', ''),
  image: {
    src: get(node, 'image.file.url', imageFallback),
    alt: get(node, 'image.description', 'None provided'),
  },
  createdAt: new Date(node.createdAt),
})
