import { ContentfulTypeResource } from './resource'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { ContentfulTypeLink, LinkItem } from './link'
import { ContentfulTypeLinkGroup, LinkGroup } from './link-group'
import { transform as toResource, Resource } from './resource'
import { MarkdownRemark } from './types/gatsby'

export type ContentfulSettingsDesignerFund = {
  id: string
  marqueeEnabled: boolean
  marquee: ContentfulTypeLink
  primaryNavigation: ContentfulTypeLink[]
  menu: ContentfulTypeLinkGroup[]
  featuredResources: ContentfulTypeResource[]
  newsletterCtaTitle: string
  footerTitle: string
  footer: ContentfulTypeLinkGroup[]
  privacyPolicy: {
    childMarkdownRemark: MarkdownRemark
  }
  termsOfUse: {
    childMarkdownRemark: MarkdownRemark
  }
}

export type Globals = {
  id: string
  marqueeEnabled: boolean
  marquee: LinkItem
  navigation: LinkItem[]
  menu: null | LinkGroup[] // TODO: why nullable?
  resources: Resource[]
  newsletterCta: {
    title: string
  }
  footer: {
    title: string
    links: LinkGroup[]
  }
  privacyPolicy: string
  termsOfUse: string
}

export const fragment = graphql`
  fragment GlobalsFields on ContentfulSettingsDesignerFund {
    id
    marqueeEnabled
    marquee {
      ...LinkFields
    }
    menu {
      ...LinkGroupFields
    }
    primaryNavigation {
      ...LinkFields
    }
    featuredResources {
      ...ResourceFields
    }
    newsletterCtaTitle
    footerTitle
    footer {
      ...LinkGroupFields
    }
    privacyPolicy {
      childMarkdownRemark {
        html
      }
    }
    termsOfUse {
      childMarkdownRemark {
        html
      }
    }
  }
`

export const transform = (node: ContentfulSettingsDesignerFund): Globals => ({
  id: node.id,
  marquee: get(node, 'marquee', null),
  marqueeEnabled: get(node, 'marqueeEnabled', false),
  menu: get(node, 'menu', []),
  navigation: get(node, 'primaryNavigation', []),
  resources: get(node, 'featuredResources', []).map(res => toResource(res)),
  newsletterCta: {
    title: get(node, 'newsletterCtaTitle', ''),
  },
  footer: {
    title: get(node, 'footerTitle', []),
    links: get(node, 'footer', []),
  },
  privacyPolicy: get(node, 'privacyPolicy.childMarkdownRemark.html', ''),
  termsOfUse: get(node, 'termsOfUse.childMarkdownRemark.html', ''),
})
