import React, { useState } from 'react'

export const ContactContext = React.createContext({})
export const ContactConsumer = ContactContext.Consumer

export function ContactProvider({ children }) {
  const [visible, setVisible] = useState(false)
  return (
    <ContactContext.Provider
      value={{
        visible,
        setVisible,
      }}
    >
      {children}
    </ContactContext.Provider>
  )
}
