import * as React from 'react'

type MCRequest = { address: string; interests?: string[] }
type MCResponse = { result: string; msg: string }
type Statuses = 'ready' | 'busy'

// TODO: MC Integration
// https://github.com/benjaminhoffman/gatsby-plugin-mailchimp
// https://github.com/gatsbyjs/gatsby/blob/master/www/src/components/email-capture-form.js

type NewsletterManagerChildProps = {
  /** Email validation */
  isValid: boolean
  /** Field submission handler */
  onSubmit: (options: MCRequest) => any
  /** API status */
  status: Statuses
  /** Mailchimp plugin responses ([API docs](https://www.gatsbyjs.org/packages/gatsby-plugin-mailchimp/)) */
  response: null | MCResponse
}

interface Props {
  /** Email address to validate */
  value: string
  /** Section heading */
  children: (props: NewsletterManagerChildProps) => React.ReactNode
  /** Mailchimp interest ids */
  interests?: string[]
  /** Event callback */
  onComplete?: (e: any) => any
}

interface State {
  /** API status */
  status: Statuses
  /** MC response */
  response: null | MCResponse
}

const SUBSCRIBE_URL = '/.netlify/functions/mailchimp-subscribe'
const DF_MASTER_LIST = '023e8e3c40'

const isEmail = str => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str)

const subscribe = async ({ address, interests = [] }: MCRequest) => {
  try {
    const request = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: address,
        list_id: DF_MASTER_LIST,
        interests,
      }),
    }
    const response = await fetch(SUBSCRIBE_URL, request)
    const json = await response.json()
    const { data, error } = json
    // console.log(data, JSON.parse(data))
    return responseDataToResult({ data, error })
  } catch (e) {
    // MC always returns a 200 so this Promise catch would only
    // occur in cases of network failure
    return {
      result: 'error',
      msg: 'Mailchimp could not be reached, please try again later',
    }
  }
}

export default class NewsletterManager extends React.Component<Props, State> {
  public constructor(props) {
    super(props)
    this.state = { response: null, status: 'ready' }
  }

  componentDidUpdate(_, prevState) {
    const { status, response } = this.state
    const { onComplete } = this.props
    if (status === 'ready' && status !== prevState.status && response) {
      // console.log("there's a response", response)
      if (onComplete) onComplete({ response })
    }
  }

  onSubmit = async ({ address }: MCRequest) => {
    if (this.state.status !== 'busy') {
      this.setState({ status: 'busy' })
      this.makeRequest({ address, interests: this.props.interests })
    }
  }

  async makeRequest({ address, interests }: MCRequest) {
    const response = await subscribe({ address, interests })
    this.setState({ status: 'ready', response })
  }

  public render() {
    const { children, value } = this.props
    const { status, response } = this.state
    return children({
      status,
      response,
      isValid: isEmail(value),
      onSubmit: this.onSubmit,
    })
  }
}

function responseDataToResult({ data, error } = {}) {
  if (error) return { result: 'error', msg: error }
  if (!data.subscribed) {
    return { result: 'error', msg: 'Failed to add address to the list' }
  }
  return {
    result: 'success',
    msg: data.existing_member
      ? "Again? Looks like you're already a member."
      : 'Successfully subscribed to the newsletter. Welcome!',
  }
}
