import * as React from 'react'

const Close = props => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.15234 10.8466L10.8447 1.1543" stroke="currentColor" />
    <path d="M1.15144 1.1543L10.8437 10.8466" stroke="currentColor" />
  </svg>
)

export default Close
