import * as React from 'react'

const LinkedIn = props => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.23727 4.16992L0.574219 4.16992L0.574217 12.1163L3.23727 12.1163L3.23727 4.16992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4139 1.7115C3.39662 0.93233 2.83559 0.338868 1.92458 0.338867C1.01356 0.338867 0.417969 0.93233 0.417969 1.7115C0.417968 2.47453 0.995956 3.08508 1.89001 3.08508L1.90703 3.08508C2.83559 3.08508 3.4139 2.47453 3.4139 1.7115Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8447 7.55918C12.8448 5.1184 11.531 3.98242 9.77858 3.98242C8.36468 3.98242 7.73166 4.7537 7.37821 5.29477L7.37821 4.16923L4.71484 4.16923C4.74994 4.91488 4.71484 12.1156 4.71484 12.1156L7.37821 12.1156L7.37821 7.67769C7.37821 7.4402 7.39549 7.20329 7.4659 7.03325C7.65837 6.5588 8.09659 6.06757 8.83231 6.06757C9.79635 6.06757 10.1817 6.79624 10.1817 7.86408L10.1817 12.1154L12.8446 12.1154L12.8447 7.55918Z"
      fill="currentColor"
    />
  </svg>
)

export default LinkedIn
